//help.relativity.com/RelativityOne/Content/Relativity/Relativity_Redact/Regular_expression_examples.htm
const abaRoutingNumber =
  "^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$";

const usBankAccountNumber = "^[0-9]{7,35}$";

const usBankAccountNumberInputRegex = "^[0-9]{0,35}$";
const usBankRoutingNumberInputRegex = "^[0-9]{0,9}$";

// monetary amounts
const amountRegex = /^[0-9]{0,78}\.?[0-9]{0,8}$/;
const fiatAmountRegex = /^[0-9]{0,78}\.?[0-9]{0,2}$/;

export {
  usBankAccountNumber,
  abaRoutingNumber,
  usBankAccountNumberInputRegex,
  usBankRoutingNumberInputRegex,
  amountRegex,
  fiatAmountRegex,
};
