import { useEffect, useState } from "react";
import { format } from "date-fns";

import API from "../api/api";
import { useAuth } from "../contexts/Auth";
import Loader from "../components/Loader";
import { useToast } from "../contexts/ToastContext";

interface AuditLog {
  id: string;
  eventName: string;
  eventDescription: Record<string, any>;
  createdAt: string;
  performedBy: string;
}

interface PaginationParams {
  page: number;
  pageSize: number;
}

export default function AuditLogs() {
  const auth = useAuth();
  const { showToast } = useToast();
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationParams>({
    page: 1,
    pageSize: 25,
  });
  const [totalPages, setTotalPages] = useState(1);

  const pageSizeOptions = [25, 50, 100];

  const formatEventDescription = (
    eventDescription?: Record<string, any>,
  ): string => {
    if (eventDescription) {
      return `${Object.entries(eventDescription)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ")}`;
    }
    return "";
  };

  useEffect(() => {
    async function fetchAuditLogs() {
      try {
        setLoading(true);
        const { data } = await API({
          url: `/business/auditLogs/${auth?.currentUser?.selectedAccount?.accountId}`,
          method: "GET",
          params: {
            page: Number(pagination.page),
            pageSize: Number(pagination.pageSize),
            sort: "desc",
          },
        });
        if (data) {
          setAuditLogs(data.logs);
          setTotalPages(
            Math.ceil(
              Number(data?.total || 0) / Number(pagination?.pageSize || 1),
            ),
          );
        }
      } catch (error: any) {
        showToast(
          `Failed to fetch audit logs: ${
            error.response?.data?.error || "Unknown error occurred"
          }`,
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (auth?.currentUser?.selectedAccount?.accountId) {
      fetchAuditLogs();
    }
  }, [auth?.currentUser?.selectedAccount?.accountId, pagination, showToast]);

  return (
    <main className="bg-grey p-6">
      <h4 className="text-xl font-bold dark:text-black mb-4">Audit Logs</h4>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-2">
          <span>Page Size:</span>
          <select
            value={pagination.pageSize}
            onChange={(e) =>
              setPagination({ page: 1, pageSize: Number(e.target.value) })
            }
            className="border rounded"
          >
            {pageSizeOptions.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="p-4 text-left text-sm font-semibold text-gray-900">
                        ID
                      </th>
                      <th className="p-4 text-left text-sm font-semibold text-gray-900">
                        Event Name
                      </th>
                      <th className="p-4 text-left text-sm font-semibold text-gray-900">
                        Event Description
                      </th>
                      <th className="p-4 text-left text-sm font-semibold text-gray-900">
                        Created At
                      </th>
                      <th className="p-4 text-left text-sm font-semibold text-gray-900">
                        Performed By
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {auditLogs.map((log) => (
                      <tr key={log.id}>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {log.id}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {log.eventName}
                        </td>
                        <td className="p-4 text-sm text-gray-500">
                          {formatEventDescription(log.eventDescription)}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {format(
                            new Date(log.createdAt),
                            "MMM dd, yyyy, h:mm a",
                          )}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {log.performedBy}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2 mt-4">
            <button
              onClick={() =>
                setPagination((prev) => ({ ...prev, page: prev.page - 1 }))
              }
              disabled={pagination.page === 1}
              className="px-3 py-1 border rounded disabled:opacity-50"
            >
              Previous
            </button>
            <span>
              Page {pagination.page} of {totalPages}
            </span>
            <button
              onClick={() =>
                setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
              }
              disabled={pagination.page === totalPages}
              className="px-3 py-1 border rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </main>
  );
}
