function formatNumberToCurrency(
  num: number = 0,
  minimumFractionDigits = 2,
): string {
  return num.toLocaleString("en-US", {
    minimumFractionDigits,
    maximumFractionDigits: 2,
  });
}

export { formatNumberToCurrency };
