import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/Auth";
import API from "../api/api";
import AddUserDialog from "./Onboarding/OnboardingDetails/OnboardingSummary/AddUser";
import ConfirmDialog from "../components/Confirmation";
import Loader from "../components/Loader";
import { useToast } from "../contexts/ToastContext";

const UserManagement: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const auth = useAuth();
  const accountId = auth?.currentUser?.selectedAccount?.accountId;
  const [users, setUsers] = useState<any[]>([]);
  const [openUserForm, setOpenUserForm] = useState(false);
  const [userToDelete, setUserToDelete] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const { showToast } = useToast();

  const deletedUsers = {};

  const handleAddUserClick = () => {
    setOpenUserForm(true);
  };

  const handleAddUser = async (userData: any) => {
    try {
      setLoading(true);
      const { data } = await API.post(`business/${accountId}/userInvite`, {
        invitedUser: { ...userData },
      });
      const updatedUsers = [...users, ...data];
      setUsers(updatedUsers);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //   setShowError(true);
    }
  };

  const deleteUser = async (userId) => {
    try {
      setDeletingUser(true);
      deletedUsers[userId] = true;

      await API.patch(`business/${accountId}/individual/${userId}`, {
        active: false,
      });

      const updatedUsers = users.filter((u) => !(u.id in deletedUsers));

      setUsers(updatedUsers);
      setDeletingUser(false);
      setUserToDelete(null);
    } catch (e) {
      delete deletedUsers[userId];
      setDeletingUser(false);
      setUserToDelete(null);
      showToast("There was an error attempting to delete this user.");
    }
  };

  useEffect(() => {
    if (!accountId) return;
    const getUsers = async () => {
      setLoading(true);
      const { data } = await API.get(`business/${accountId}/users`);
      setUsers(data);
      setLoading(false);
    };
    getUsers();
    return () => {
      setUsers([]);
    };
  }, [accountId]);
  return (
    <>
      {loading && <Loader />}
      {openUserForm && (
        <AddUserDialog
          open={openUserForm}
          setOpen={setOpenUserForm}
          onSubmit={handleAddUser}
        />
      )}
      {!!userToDelete && (
        <ConfirmDialog
          isOpen={!!userToDelete}
          onClose={() => {
            setUserToDelete(null);
          }}
          onConfirm={(userId) => {
            deleteUser(userId);
          }}
          onCancel={() => {
            setUserToDelete(null);
          }}
          title={`Delete User?`}
          description={`Are you sure you want to delete ${userToDelete?.name}? This action cannot be undone.`}
          confirmText="Delete"
          cancelText="Cancel"
          valueToPassBack={userToDelete?.id}
        />
      )}
      <main className="bg-grey p-6">
        <div className="flex flex-row items-center mb-2">
          <h4 className="text-xl font-bold dark:text-black">User Management</h4>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700">
              Users have access to view transaction and wallet information.
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-right text-sm font-semibold text-gray-900"
                      >
                        <button
                          type="button"
                          className="rounded bg-lavender-400 hover:bg-lavender-600 px-2 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lavender-600 disabled:bg-lavender-200 disabled:text-lavender-700 disabled:opacity-50"
                          onClick={handleAddUserClick}
                          disabled={loading || !accountId}
                        >
                          Add User
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {users.map((user) => (
                      <tr key={user.id}>
                        <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-6">
                          {user.name}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {user.email}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {!!user?.authentication_id ? (
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              Active
                            </span>
                          ) : (
                            <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                              Pending
                            </span>
                          )}
                        </td>
                        <td className="relative whitespace-nowrap p-4 text-right text-sm font-medium">
                          <button
                            disabled={
                              !user?.active || deletingUser || !!userToDelete
                            }
                            className="text-gray-500 cursor-pointer disabled:cursor-not-allowed"
                            onClick={() => {
                              setUserToDelete({ id: user.id, name: user.name });
                            }}
                          >
                            Delete<span className="sr-only"></span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserManagement;
