import React from "react";
import Loader from "../../components/Loader";
// import API from "../../api/api";
import BridgeTermsIframe from "../../components/BridgeTermsIframe";

interface Props {
  onAccept: (data: { bridgeSignedAgreementId: string }) => void;
}

const MyComponent: React.FC<Props> = ({ onAccept }) => {
  // bridge
  const [bridgeTermsUrl] = React.useState<string>("");
  const [bridgeSignedAgreementId, setBridgeSignedAgreementId] =
    React.useState<string>("");

  // const getBridgeTermsUrl = async () => {
  //   const { data }: { data: { url: string } } = await API.get(
  //     "business/terms/bridge",
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );
  //   setBridgeTermsUrl(data.url);
  // };

  const [loading, setLoading] = React.useState<boolean>(false);
  const acceptTerms = async () => {
    setLoading(true);
    onAccept({ bridgeSignedAgreementId });
  };
  return (
    <>
      {process.env.NODE_ENV === "development" &&
        bridgeTermsUrl &&
        !bridgeSignedAgreementId && (
          <BridgeTermsIframe
            url={bridgeTermsUrl}
            onAccept={({ signedAgreementId }) => {
              setBridgeSignedAgreementId(signedAgreementId);
            }}
          />
        )}
      <div className="overflow-auto">
        {/* <button disabled={!!bridgeTermsUrl} onClick={getBridgeTermsUrl}>
          Get Bridge Terms
        </button>
        <p className="text-lg leading-8 text-gray-600">
          By clicking Accept you agree to the following
        </p> */}
        <div className="mt-4">
          <a
            href="https://www.getbeam.cash/privacypolicy"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            Ansible Labs Privacy Policy
          </a>
        </div>
        <div className="mt-4">
          <a
            href="https://www.getbeam.cash/terms-business"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            Ansible Labs Terms of Use
          </a>
        </div>
        <div className="mt-4">
          <a
            href="https://vault.sandbox.fortressapi.com/nft-origin/8321fd2d-bfbc-430d-ad74-17f382c03dc7.pdf"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            Fortress Trust Custodial Account Agreement
          </a>
        </div>
        <div className="mt-4">
          <a
            href="https://fortresstrustcompany.com/disclosures-e-sign"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            E-Sign Agreement
          </a>
        </div>
        <div className="mt-4">
          <a
            href="https://fortresstrustcompany.com/disclosures-consumer"
            target="_blank"
            className="text-blue-500 hover:text-blue-700 underline"
            rel="noreferrer"
          >
            Consumer Disclosures
          </a>
        </div>
        <div className="mt-6">
          <p className="text-md dark:text-black">USA PATRIOT ACT DISCLOSURE</p>
          <p className="text-sm text-gray-600 mt-6">
            IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To
            help the government fight money laundering and the funding of
            terrorism, federal law requires all financial institutions to
            obtain, verify, and record information that identifies each person
            or entity they conduct business with. What this means for you: we
            will ask for information pertaining to your business that will allow
            us to verify the entity, such as the entity name and structure,
            jurisdiction of formation, entity’s physical address, and other
            information that will allow us to identify the business. We will
            also ask for information pertaining to ultimate beneficial owners
            (“UBOs”), who are individuals or entities that own or control at
            least 25% of your business, as well as any Officers, Directors, and
            Authorized Users of the business.
          </p>
        </div>
        <button
          onClick={acceptTerms}
          type="button"
          className="rounded-md bg-lavender-400 hover:bg-lavender-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-8"
          disabled={loading}
        >
          Accept
        </button>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default MyComponent;
