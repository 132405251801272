import React, { useEffect, useState } from "react";

import Loader from "../components/Loader";
import API from "../api/api";

import { ReactComponent as ArbitrumIcon } from "../assets/arbitrum.svg";
import { ReactComponent as EthereumIcon } from "../assets/eth.svg";
import { ReactComponent as PolygonIcon } from "../assets/polygon.svg";
import { ReactComponent as SolanaIcon } from "../assets/sol.svg";
import { ReactComponent as XRPIcon } from "../assets/xrp.svg";
import { ReactComponent as DOGEIcon } from "../assets/dogecoin.svg";
import { ReactComponent as BTCIcon } from "../assets/bitcoin.svg";
import { ReactComponent as StellarIcon } from "../assets/stellar.svg";
import { ReactComponent as AvalanceIcon } from "../assets/avax.svg";
import { ReactComponent as USDCIcon } from "../assets/usd-circle.svg";

interface IRate {
  icon: any;
  name: string;
  network: string;
  marketPrice: number;
  ansibleFeePercent: number;
  ansibleFeeValue: number;
  estimatedPayoutValue: number;
  symbol: string;
}

export default function Market() {
  const [loading, setLoading] = useState(false);
  const [rates, setRates] = useState<Array<IRate>>([]);

  function getIcon(name: string) {
    switch (name) {
      case "Bitcoin":
        return <BTCIcon className="ml-2" height={25} />;
      case "Ethereum":
        return <EthereumIcon className="ml-2" height={25} />;
      case "Ripple":
        return <XRPIcon className="ml-2" height={25} />;
      case "Dogecoin":
        return <DOGEIcon className="ml-2" height={25} />;
      case "Solana":
        return <SolanaIcon className="ml-2" height={25} />;
      case "Polygon":
        return <PolygonIcon className="ml-2" height={25} />;
      case "Stellar":
        return <StellarIcon className="ml-2" height={25} />;
      case "AvalanceIcon":
        return <AvalanceIcon className="ml-2" height={25} />;
      case "USDC":
        return <USDCIcon className="ml-2" height={25} />;
      default:
        return <ArbitrumIcon className="ml-2" height={25} />;
    }
  }

  async function getRates() {
    setLoading(true);
    const { data } = await API.get(`business/rates`);
    setRates(data);
    setLoading(false);
  }

  useEffect(() => {
    getRates();
    const intervalCall = setInterval(async () => {
      await getRates();
    }, 10000);

    return () => {
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader />}
      <main className="bg-grey p-6">
        <div className="flex flex-row items-center mb-2">
          <h4 className="text-xl font-bold dark:text-black">Market</h4>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto"></div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900"
                      >
                        Symbol
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900"
                      >
                        Market Price
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900"
                      >
                        Ansible Fee
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold text-gray-900"
                      >
                        Estimated Payout
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {rates.map((rate) => (
                      <tr key={rate.symbol}>
                        <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900">
                          <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0 flex items-center h-full">
                              {getIcon(rate.name)}
                            </div>
                            <div className="font-medium text-gray-900 flex direction-row ml-2">
                              {rate.name}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {rate.symbol}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          ${rate.marketPrice}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          ${rate.ansibleFeeValue} ({rate.ansibleFeePercent}%)
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          ${rate.estimatedPayoutValue}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <main className="bg-grey p-2">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-auto"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Market Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Ansible Fee
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Estimated Payout Value
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {rates.map((rate) => (
                      <tr key={rate.symbol}>
                        <td className="whitespace-nowrap py-5 pl-4 text-sm sm:pl-0 w-auto">
                          <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0 flex items-center h-full">
                              {getIcon(rate.name)}
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {rate.name}
                              </div>
                              <div className="mt-1 text-gray-500">
                                {rate.symbol}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          ${rate.marketPrice}
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          ${rate.ansibleFeeValue} ({rate.ansibleFeePercent}%)
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          ${rate.estimatedPayoutValue}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </>
  );
}
