import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  NewspaperIcon,
  ChartBarSquareIcon,
  ChatBubbleLeftIcon,
  ArrowLeftIcon,
  XMarkIcon,
  WalletIcon,
  RectangleStackIcon,
  CodeBracketSquareIcon,
  UsersIcon,
  Cog6ToothIcon,
  DocumentIcon,
  DevicePhoneMobileIcon,
  LinkIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import { usePrivy } from "@privy-io/react-auth";
// import { Announcements } from "@ansiblelabsxyz/beam-common/prisma";

import AnsibleBeamIcon from "../assets/beam-logo-black-period.png";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/Auth";

const navigation = [
  {
    name: "Account",
    icon: RectangleStackIcon,
    current: true,
    path: "/accounts",
    options: [
      {
        name: "Balances",
        icon: WalletIcon,
        current: false,
        path: "/balances",
      },
      {
        name: "Payment Settings",
        icon: Cog6ToothIcon,
        path: "/settings",
      },
      {
        name: "Transactions",
        icon: NewspaperIcon,
        current: false,
        path: "/transactions",
      },
      {
        name: "Connected Accounts",
        icon: LinkIcon,
        path: "/connected-accounts",
      },
      {
        name: "Statements",
        icon: DocumentIcon,
        path: "/statements",
      },
      {
        name: "User Management",
        icon: UsersIcon,
        path: "/user-management",
      },
      {
        name: "Audit Logs",
        icon: ShieldCheckIcon,
        path: "/audit-logs",
      },
      {
        name: "Core API",
        icon: CodeBracketSquareIcon,
        current: false,
        path: "/api",
      },
    ],
  },
  {
    name: "Market",
    icon: ChartBarSquareIcon,
    current: false,
    path: "/market",
  },
  {
    name: "Embedded",
    icon: DevicePhoneMobileIcon,
    current: false,
    path: "/embedded",
    options: [
      {
        name: "Users",
        icon: UsersIcon,
        current: false,
        disabled: true,
      },
      {
        name: "Settings",
        icon: Cog6ToothIcon,
        current: false,
        disabled: true,
      },
    ],
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Internal() {
  const { logout } = usePrivy();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // TODO: look into why this calls on each render, backend also is throwing error
  // const { data: announcementQuery } = useQuery({
  //   queryKey: ["announcement"],
  //   queryFn: async () => {
  //     return await API.get("/business/announcements/latest");
  //   },
  // });
  // const announcement: Announcements | null = announcementQuery?.data;
  // const announcement: any | null = announcementQuery?.data;
  // const announcementDismissed =
  //   localStorage.getItem(announcement?.id) === "true";
  // const [showAnnouncement, setShowAnnouncement] = useState(
  //   !!announcement && !announcementDismissed
  // );

  // useEffect(() => {
  //   setShowAnnouncement(!!announcement && !announcementDismissed);
  // }, [announcementDismissed, announcement]);

  async function signOut() {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div style={{ height: "100%" }}>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={AnsibleBeamIcon}
                        alt="Ansible Labs"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <button
                                  onClick={() => navigate(item.path)}
                                  className={classNames(
                                    location.pathname.includes(item.path)
                                      ? "bg-gray-50 text-lavender-800"
                                      : "text-gray-700 hover:text-lavender-800 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center",
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      location.pathname.includes(item.path)
                                        ? "text-lavender-800"
                                        : "text-gray-400 group-hover:text-lavender-800",
                                      "h-6 w-6 shrink-0",
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </button>
                                {item.options && (
                                  <ul className="mt-2 space-y-1 ml-10">
                                    {item.options.map((option) => (
                                      <li key={option.name}>
                                        <button
                                          disabled={option.disabled}
                                          onClick={() => navigate(option.path)}
                                          className={classNames(
                                            location.pathname.includes(
                                              option.path,
                                            )
                                              ? "bg-gray-50 text-lavender-800"
                                              : "text-gray-700 hover:text-lavender-800 hover:bg-gray-50",
                                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center disabled:text-gray-400 disabled:cursor-not-allowed",
                                          )}
                                        >
                                          <option.icon
                                            className={classNames(
                                              location.pathname.includes(
                                                option.path,
                                              )
                                                ? "text-lavender-800"
                                                : "text-gray-400 group-hover:text-lavender-800",
                                              "h-5 w-5 shrink-0",
                                            )}
                                            aria-hidden="true"
                                          />
                                          {option.name}
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>

                        <li className="mt-auto">
                          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                          {auth?.currentUser?.assignedAccounts?.length > 1 && (
                            <select
                              id="assignedAccounts"
                              name="assignedAccounts"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 my-4"
                              defaultValue={
                                auth?.currentUser?.selectedAccount?.accountId
                              }
                              onChange={(e) => {
                                auth.updateUser({
                                  selectedAccount: {
                                    accountId:
                                      auth?.currentUser?.assignedAccounts.find(
                                        (account) =>
                                          account.accountId === e.target.value,
                                      )?.accountId,
                                  },
                                });
                              }}
                            >
                              {auth?.currentUser?.assignedAccounts.map(
                                (account, idx) => (
                                  <option
                                    value={account.accountId}
                                    key={`${idx}-${account.accountId}`}
                                  >
                                    {account.name ?? account.accountId}
                                  </option>
                                ),
                              )}
                            </select>
                          )}
                          <ul>
                            <li>
                              <button className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-lavender-800 w-full">
                                <ChatBubbleLeftIcon
                                  className="text-gray-400 group-hover:text-lavender-800 h-6"
                                  onClick={() =>
                                    (window.location.href =
                                      "mailto:support@getbeam.cash?subject=Feedback")
                                  }
                                />
                                Support
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={signOut}
                                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-lavender-800 w-full"
                              >
                                <ArrowLeftIcon className="text-gray-400 group-hover:text-lavender-800 h-6" />
                                Logout
                              </button>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* {showAnnouncement && (
          <Banner
            announcementId={announcement.id}
            title={announcement.title}
            description={announcement.description}
            linkDescription={announcement.linkDescription}
            linkHref={announcement.linkHref}
            onClose={() => setShowAnnouncement(false)}
          />
        )} */}

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center justify-start">
              <img
                className="h-8 w-auto"
                src={AnsibleBeamIcon}
                alt="Ansible Labs"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <button
                          onClick={() => navigate(item.path)}
                          className={classNames(
                            location.pathname.includes(item.path)
                              ? "bg-gray-50 text-lavender-800"
                              : "text-gray-700 hover:text-lavender-800 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center",
                          )}
                        >
                          <item.icon
                            className={classNames(
                              location.pathname.includes(item.path)
                                ? "text-lavender-800"
                                : "text-gray-400 group-hover:text-lavender-800",
                              "h-5 w-5 shrink-0",
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </button>
                        {item.options && (
                          <ul className="mt-2 space-y-1 ml-10">
                            {item.options.map((option) => (
                              <li key={option.name}>
                                <button
                                  disabled={option.disabled}
                                  onClick={() => navigate(option.path)}
                                  className={classNames(
                                    location.pathname.includes(option.path)
                                      ? "bg-gray-50 text-lavender-800"
                                      : "text-gray-700 hover:text-lavender-800 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center disabled:text-gray-400 disabled:cursor-not-allowed",
                                  )}
                                >
                                  <option.icon
                                    className={classNames(
                                      location.pathname.includes(option.path)
                                        ? "text-lavender-800"
                                        : "text-gray-400 group-hover:text-lavender-800",
                                      "h-5 w-5 shrink-0",
                                    )}
                                    aria-hidden="true"
                                  />
                                  {option.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  {auth?.currentUser?.assignedAccounts?.length > 1 && (
                    <select
                      id="location"
                      name="location"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 my-4"
                      defaultValue="Canada"
                      onChange={(e) => {
                        auth.updateUser({
                          selectedAccount: {
                            ...auth?.currentUser?.assignedAccounts.find(
                              (account) => account.accountId === e.target.value,
                            ),
                          },
                        });
                      }}
                    >
                      {auth?.currentUser?.assignedAccounts.map(
                        (account, idx) => (
                          <option
                            value={account.accountId}
                            key={`${idx}-${account.accountId}`}
                          >
                            {account.name || account.accountId}
                          </option>
                        ),
                      )}
                    </select>
                  )}
                  <ul>
                    <li>
                      <button
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-lavender-800 w-full"
                        onClick={() =>
                          (window.location.href =
                            "mailto:support@getbeam.cash?subject=Feedback")
                        }
                      >
                        <ChatBubbleLeftIcon className="text-gray-400 group-hover:text-lavender-800 h-6" />
                        Support
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={signOut}
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-lavender-800 w-full"
                      >
                        <ArrowLeftIcon className="text-gray-400 group-hover:text-lavender-800 h-6" />
                        Logout
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72 h-full">
          <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
              Onboarding
            </div> */}
          </div>

          <main className="bg-grey p-2 h-full overflow-auto">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}
